<template>
	<div class="p-grid crud-demo" >
		<div class="p-col-12" v-if="!detalles">
			<div class="card">
				<Toolbar class="p-col-12 p-md-12">
					<template v-slot:left>
						<Dropdown v-model="periodo" :options="periodos" optionLabel="per" placeholder="Seleccione el Período ..."/>
						<Dropdown id="state" v-model="opcion" :options="opciones" optionLabel="nombre" placeholder="Selecciona..." @change="Buscar()" v-if="periodo"></Dropdown>
						<Dropdown v-model="nucleo" :options="nucleos" optionLabel="nombre" placeholder="Seleccione la Extensión ..." v-if="opcion" @change="Filtrar()"/>
					</template>
					<template v-slot:right>
                    </template>
				</Toolbar>

				<DataTable :value="products" ref="dt" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Total: {{products.length}}</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="nucleo" header="Extensión" :sortable="true" headerStyle="width: 300px">
						<template #body="slotProps">
							{{slotProps.data.nucleo}}
						</template>
					</Column>
					<Column field="periodo" header="Período" :sortable="true" headerStyle="width: 100px">
						<template #body="slotProps">
							<div v-if="slotProps.data.periodo">{{slotProps.data.periodo}}</div>
						</template>
					</Column>
					<Column field="fechad" header="Fecha" :sortable="true" headerStyle="width: 100px">
						<template #body="slotProps">
							<div v-if="slotProps.data.fechad">{{slotProps.data.fechad}}</div>
						</template>
					</Column>
					<Column field="programa" header="Plan de Estudio" :sortable="true" headerStyle="width: 400px">
						<template #body="slotProps">
							{{slotProps.data.programa}} {{slotProps.data.cod}}
						</template>
					</Column>
					<Column field="cant" header="N° Graduandos" headerStyle="width: 120px">
						<template #body="slotProps">
							{{slotProps.data.cant}}
						</template>
					</Column>
					<Column field="actas" header="N° Actas" :sortable="true" headerStyle="width: 120px">
						<template #body="slotProps">
							<div v-if="slotProps.data.actas">{{slotProps.data.actas}}</div>
						</template>
					</Column>
					<Column field="tomo" header="N° Tomo" :sortable="true" headerStyle="width: 120px">
						<template #body="slotProps">
							<div v-if="slotProps.data.tomo">{{Romano(slotProps.data.tomo)}}</div>
						</template>
					</Column>
					<Column field="folio" header="N° Folio" :sortable="true" headerStyle="width: 120px">
						<template #body="slotProps">
							<div v-if="slotProps.data.folio">{{slotProps.data.folio}}</div>
							<div v-else></div>
						</template>
					</Column>
					<Column field="asignados" header="N° Asignados" :sortable="true" headerStyle="width: 140px">
						<template #body="slotProps">
							<div v-if="slotProps.data.asignados">{{slotProps.data.asignados}}</div>
						</template>
					</Column>
					<Column header="Opciones" headerStyle="width: 200px">
						<template #body="slotProps">
							<Button icon="pi pi-eye" class="p-button-rounded p-button-success p-mr-2" v-tooltip.bottom="'Ver'"  @click="VerGrad(slotProps.data)"/>
							<Button icon="pi pi-upload" class="p-button-rounded p-button-info p-mr-2" v-tooltip.bottom="'Exportar'"  @click="VerXLS(slotProps.data)" v-if="opcion.code==1"/>
							<!--<Button icon="pi pi-file-pdf" class="p-button-rounded p-button-info p-mr-2" @click="VerPDF(slotProps.data)" v-if="opcion.code==1"/>-->
							<Button icon="pi pi-play" class="p-button-rounded p-button-secondary p-mr-2" v-tooltip.bottom="'Procesar'"  @click="VerProc(slotProps.data)" v-if="opcion.code==2"/>
							<Button icon="pi pi-replay" class="p-button-rounded p-button-warning p-mr-2" v-tooltip.bottom="'Reversar'"  @click="VerReverso(slotProps.data)" v-if="opcion.code==1 && slotProps.data.id==products[0].id"/>
						</template>
					</Column>
				</DataTable>
			</div>

		</div>

		<Dialog v-model:visible="verDialog" :style="{width: size}" header="Detalle" :modal="true" class="p-fluid" :maximizable="true">
			<br>
            <div class="p-field">
				<div class="p-grid">
					<div class="p-col-2">
						<strong>Período: </strong>{{seleccion.periodo}}
					</div>
					<div class="p-col-2">
						<strong>Fecha: </strong>{{seleccion.fechad}}
					</div>
					<div class="p-col-2">
						<strong>N° de Graduando: </strong>{{seleccion.cant}}
					</div>
					<div class="p-col-6">
						<strong>Plan de Estudio: </strong>{{seleccion.programa}}
					</div>
				</div>
				<br>
				<div class="p-grid">
					<div class="p-col-3">
						<label class="p-mb-3">Fecha de Grado</label>
						<InputText v-model="seleccion.fecha" type="date"/>
					</div>
					<div class="p-col-3">
						<label class="p-mb-3">N° Resuelto</label>
						<InputText v-model="seleccion.resuelto" />
					</div>
					<div class="p-col-3">
						<label class="p-mb-3">Fecha de Resuelto</label>
						<InputText v-model="seleccion.resuelto_fecha" type="date"/>
					</div>
					<div class="p-col-3">
						<Button label="Guardar" icon="pi pi-check" class="p-button-secondary" @click="Actualizar()" />
					</div>
				</div>
				<br>
				<div class="p-grid">
					<h5><strong>Graduandos</strong></h5>
					<DataTable :value="seleccion.graduandos" ref="dt" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm">
						<Column field="nombre" header="Nombres y Apellidos">
							<template #body="slotProps">
								{{slotProps.data.nombre}} {{slotProps.data.apellido}}
							</template>
						</Column>
						<Column field="estudiante" header="Cédula de Identidad" headerStyle="width: 150px">
							<template #body="slotProps">
								{{slotProps.data.nac}}-{{slotProps.data.estudiante}}
							</template>
						</Column>
						<Column field="titulo" header="Título">
							<template #body="slotProps">
								{{slotProps.data.titulo}}
							</template>
						</Column>
						<Column field="indiceg" header="Índice Grado" headerStyle="width: 100px">
							<template #body="slotProps">
								{{slotProps.data.indiceg}}
							</template>
						</Column>
						<Column field="libro" header="N° Libro" headerStyle="width: 90px">
							<template #body="slotProps">
								<strong v-if="slotProps.data.detalle">
									{{Romano(slotProps.data.detalle.libro)}}
								</strong>
							</template>
						</Column>
						<Column field="acta" header="N° Acta Grado" headerStyle="width: 90px">
							<template #body="slotProps">
								<strong v-if="slotProps.data.detalle.acta>0">
									{{slotProps.data.detalle.acta}}
								</strong>
							</template>
						</Column>
						<Column field="tomo" header="N° Tomo" headerStyle="width: 90px">
							<template #body="slotProps">
								<strong v-if="slotProps.data.detalle">
									{{Romano(slotProps.data.detalle.tomo)}}
								</strong>
							</template>
						</Column>
						<Column field="folio" header="N° Folio" headerStyle="width: 90px">
							<template #body="slotProps">
								<strong v-if="slotProps.data.detalle">
									{{slotProps.data.detalle.folio}}
								</strong>
							</template>
						</Column>
						<Column field="asignado" header="N° Asignado" headerStyle="width: 120px">
							<template #body="slotProps">
								<strong v-if="slotProps.data.detalle.asignado>0">
									{{slotProps.data.detalle.asignado}}
								</strong>
							</template>
						</Column>
				</DataTable>
				</div>
			</div>
			<template #footer>
				<Button label="Cerrar" icon="pi pi-times" class="p-button-danger" @click="verDialog = false"/>
			</template>
		</Dialog>

		<Dialog v-model:visible="procesoDialog" :style="{width: size}" header="Procesar" :modal="true" class="p-fluid" :maximizable="true">
			<br>
            <div class="p-field">
				<div class="p-grid">
					<div class="p-col-12">
						<strong>Extensión: </strong>{{seleccion.nucleo}}
					</div>
					<div class="p-col-12">
						<strong>Plan de Estudio: </strong>{{seleccion.programa}}
					</div>
					<div class="p-col-4">
						<strong>Período: </strong>{{seleccion.periodo}}
					</div>
					<div class="p-col-4">
						<strong>Fecha: </strong>{{seleccion.fechad}}
					</div>
					<div class="p-col-4">
						<strong>N° de Graduando: </strong>{{seleccion.cant}}
					</div>
					<div class="p-col-4">
						<label class="p-mb-3">N° Tomo: {{Romano(tomo)}} (Registros: {{tomo_cant}})</label>
						<InputNumber id="sem" v-model="tomo" required="true"/>
					</div>
					<div class="p-col-4">
						<label class="p-mb-3">N° Folio: {{folio}} (Registros: {{folio_cant}})</label>
						<InputNumber id="sem" v-model="folio" required="true"/>
					</div>
					<div class="p-col-4">
						<label class="p-mb-3">N° Asignado: {{asignado.toString().padStart(4,'0')}}</label>
						<InputNumber id="sem" v-model="asignado" required="true"/>
					</div>
				</div>
			</div>
			<template #footer>
				<Button label="Procesar" icon="pi pi-check" class="p-button-secondary" @click="Procesar()" />
				<Button label="Cerrar" icon="pi pi-times" class="p-button-danger" @click="procesoDialog = false"/>
			</template>
		</Dialog>

		<Dialog v-model:visible="reversoDialog" :style="{width: size}" header="Reversar Acta" :modal="true" class="p-fluid" :maximizable="true">
			<br>
            <div class="p-field">
				<div class="p-grid">
					<div class="p-col-12">
						<strong>Extensión: </strong>{{seleccion.nucleo}}
					</div>
					<div class="p-col-12">
						<strong>Plan de Estudio: </strong>{{seleccion.programa}}
					</div>
					<div class="p-col-4">
						<strong>Período: </strong>{{seleccion.periodo}}
					</div>
					<div class="p-col-4">
						<strong>Fecha: </strong>{{seleccion.fechad}}
					</div>
					<div class="p-col-4">
						<strong>N° de Graduando: </strong>{{seleccion.cant}}
					</div>
				</div>
			</div>
			<template #footer>
				<Button label="Reversar" icon="pi pi-check" class="p-button-secondary" @click="Reverso()" />
				<Button label="Cerrar" icon="pi pi-times" class="p-button-danger" @click="reversoDialog = false"/>
			</template>
		</Dialog>


	</div>

</template>

<script>
	import API from "../service/API";
	import PDF from "../service/PDF";
	import XLS from "../service/XLS";
	import Rom from "../service/Num_Romano";
	const Consulta = new API('Egreso','Acta');

	export default {
		components: {
		},
		data() {
			return {
				insert: false,
				edit: false,
				del: false,
				titulo: null,
				products: [],
				pendientes: [],
				productDialog: false,
				procesoDialog: false,
				reversoDialog: false,
				verDialog: false,
				product: {},
				datos: {},
				selectedProducts: null,
				filters: {},
				submitted: false,
				size: '60vw',
				nucleo: null,
				nucleos: [{nombre: 'TODOS', code: 0}],
				plan: null,
				planes: [],
				periodo: null,
				periodos: [],
				detalles: null,
				visibletitulo: false,
				opcion: null,
				opciones: [
					{nombre: 'Procesadas', code: 1},
					{nombre: 'Pendientes', code: 2},
				],
				matricula: {},
				displayCalificaciones: false,
				calificaciones: [],
				seleccion: {},
				asignado: 0,
				tomo: 0,
				tomo_cant: 0,
				folio: 0,
				nuevo_folio: false,	
			}
		},
		productService: null,
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			onMenuToggle(event) {
				this.$emit('menu-toggle', event);
			},
			VerPDF(acta){
				const doc = new PDF();
				Consulta.Procesar('VerGrad',{
					nucleo: 	this.nucleo.code,
					opcion: 	this.opcion.code,
					seleccion: 	acta
				}).then(response => {
					//this.$store.state.error = response.result;
					this.$store.commit('Loading');
					doc.RegAcad(acta,response.result);
				}); 							
			},
			VerXLS(acta){
				const xls = new XLS();
				Consulta.Procesar('VerGrad',{
					nucleo: 	this.nucleo.code,
					opcion: 	this.opcion.code,
					seleccion: 	acta
				}).then(response => {
					//this.$store.state.error = response.result[0];
					this.$store.commit('Loading');
					xls.RegAcad(acta,response.result);
				}); 							
			},
			VIMG(data){
				window.open(data, '_blank');
			},
			Mostrar() {
				this.nucleos = [{nombre: 'TODOS', code: 0}];
				this.nucleo = {nombre: 'TODOS', code: 0};
				Consulta.Ini().then(result => {
					//this.$store.state.error = result;
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					this.periodos = result.consult.periodos;
					this.nucleos = [{nombre: 'TODOS', code: 0}].concat(result.consult.nucleos);
					this.$store.commit('Loading');
				}); 
			},
			Buscar() {
				if (this.opcion != null && this.periodo != null) {
					this.nucleo = {nombre: 'TODOS', code: 0};
					this.pendientes = [];
                    this.products = [];
					Consulta.Procesar('Buscar',{
						periodo: 	this.periodo.code,
						opcion: 	this.opcion.code,
					}).then(response => {
						//this.$store.state.error = response;
						this.pendientes = response.result;
						this.products = response.result;
						this.$store.commit('Loading');
					}); 
				}
			},
			Filtrar() {
				if(this.nucleo.code>0){
					this.products = this.pendientes.filter(val => val.nucleo_id == this.nucleo.code);
				} else {
					this.products = this.pendientes;
				}
			},
			VerGrad(seleccion) {
				this.seleccion = {};
				this.seleccion = {...seleccion};
				this.seleccion.graduandos = [];
				Consulta.Procesar('VerGrad',{
					opcion: 	this.opcion.code,
					seleccion: 	seleccion
				}).then(response => {
					//this.$store.state.error = response.result[0];
					this.seleccion.graduandos = response.result;
					this.$store.commit('Loading');
					this.verDialog = true;
				}); 				
			},
			VerProc(seleccion) {
				this.nuevo_folio = false;
				this.seleccion = {};
				this.seleccion = {...seleccion};
				this.fecha = null;
				Consulta.Procesar('VerProc',{
					seleccion: 	seleccion
				}).then(response => {
					//this.$store.state.error = response.result;
					this.asignado = response.result.asignado;
					this.tomo = response.result.tomo;
					this.tomo_cant = response.result.tomo_cant;
					this.folio = response.result.folio;
					this.folio_cant = response.result.folio_cant;
					this.$store.commit('Loading');
					this.procesoDialog = true;
				}); 
			},
			Procesar() {
				Consulta.Procesar('Procesar',{
					seleccion: 	this.seleccion,
					nuevo_folio: this.nuevo_folio,
					tomo: this.tomo,
					folio: this.folio,
					asignado: this.asignado,
				}).then(response => {
					//this.$store.state.error = response;
					this.$store.commit('Loading');
					if(response.result){
						this.procesoDialog = false;
						this.opcion = this.opciones[0];
						this.Buscar();
					}
				}); 		
			},
			VerReverso(seleccion) {
				//this.$store.commit('Loading');
				this.seleccion = {};
				this.seleccion = {...seleccion};
				this.reversoDialog = true;
			},
			Reverso() {
				Consulta.Procesar('Reverso',{
					opcion: 	this.opcion.code,
					seleccion: 	this.seleccion
				}).then(response => {
					//this.$store.state.error = response;
					this.seleccion.graduandos = response.result;
					this.reversoDialog = false;
					this.$store.commit('Loading');
					this.Buscar();
				}); 				
			},
			Romano(num) {
				return Rom(num);
			},
			Actualizar() {
				var selec = {};
				selec.id = this.seleccion.id;
				selec.fecha = this.seleccion.fecha;
				selec.resuelto = this.seleccion.resuelto;
				selec.resuelto_fecha = this.seleccion.resuelto_fecha;
				Consulta.Procesar('Actualizar',{
					seleccion: 	selec
				}).then(response => {
					this.$store.commit('Loading');
					//this.$store.state.error = response;
					if(response.result){
						this.verDialog = false;
						this.Buscar();
					}
				}); 				
			},



		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
