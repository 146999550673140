<template>
	<div class="p-grid crud-demo" >
		<div class="p-col-12">
			<div class="card">
				<Toolbar class="p-col-12 p-md-12">
					<template v-slot:left>
							<Dropdown v-model="nucleo" :options="nucleos" optionLabel="nombre" placeholder="Seleccione la Extensión ..." @change="periodo=null"/>
							<Dropdown v-model="periodo" :options="periodos" optionLabel="per" placeholder="Seleccione..." v-if="nucleo" @change="Buscar"/>
					</template>				
					<template v-slot:right>
						<Button label="Buscar" icon="pi pi-search" class="p-button-success p-mr-2" @click="Buscar" v-if="opcion"/>
					</template>
				</Toolbar>
				<DataTable :value="products" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Estudiantes: {{products.length}}</h5>
							<Button icon="pi pi-upload" class="p-button-rounded p-button-help p-mr-2" @click="VerXLS()" v-if="products.length>0"/>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="plan_estudio" header="Carrera" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.plan_estudio}}
						</template>
					</Column>
					<Column field="estudiante" header="N° Cédula" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.estudiante}}
						</template>
					</Column>
					<Column field="nombre" header="Nombre y Apellido" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.nombre}} {{slotProps.data.apellido}}
						</template>
					</Column>
					<Column field="asignaturas" header="Nº Asignaturas">
						<template #body="slotProps">
							{{slotProps.data.asignaturas}}
						</template>
					</Column>
					<Column field="semestre" header="Semestre">
						<template #body="slotProps">
							{{slotProps.data.semestre}}
						</template>
					</Column>
					<Column field="factura" header="Nº Recibo" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.factura}}
						</template>
					</Column>
					<Column field="monto" header="Monto">
						<template #body="slotProps">
							<strong v-if="periodo.moneda==1">Ref.</strong>
							<img :src="'images/moneda'+periodo.moneda+'.png'" width="15" v-if="periodo.moneda"/>
							{{slotProps.data.monto}}
						</template>
					</Column>
					<Column field="formapago" header="Forma de Pago">
						<template #body="slotProps">
							<strong v-if="slotProps.data.formapago==1">Al Contado</strong>
							<strong v-if="slotProps.data.formapago==2">A Credito</strong>
							<strong v-if="slotProps.data.formapago==3">Al Contado Una Materia</strong>
							<strong v-if="slotProps.data.formapago==0">No Definido</strong>
						</template>
					</Column>
					<Column field="beca" header="Becario">
						<template #body="slotProps">
							<i class="pi pi-check" v-if="slotProps.data.mat.beca"></i>
						</template>
					</Column>
				</DataTable>
			</div>
		</div>

	</div>
</template>

<script>
	import API from "../service/API";
	import XLS from "../service/XLS";
	const Consulta = new API('Pagos','PagosInscriptos');

	export default {
		data() {
			return {
				nucleo: null,
				nucleos: [],
				periodo: null,
				periodos: [],
				buscar: null,
				insert: false,
				edit: false,
				del: false,
				titulo: null,
				products: [],
				detalles: null,
				product: {},
				estudiante: {},
				selectedProducts: null,
				filters: {},
				submitted: false,
				size: '60vw',
				imagen: null
			}
		},
		productService: null,
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
                this.products = [];
				Consulta.Ini().then(result => {
					//this.$store.state.error = result.consult;
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					this.nucleos = result.consult.nucleos;				
					this.periodos = result.consult.periodos;				
					this.$store.commit('Loading');
				}); 
			},
			onMenuToggle(event) {
				this.$emit('menu-toggle', event);
			},
			Buscar() {
				if(this.periodo && this.nucleo){
					this.products = [];
					Consulta.Procesar('Buscar',{
						nucleo: this.nucleo,
						periodo: this.periodo,
					}).then(response => {
						//this.$store.state.error =response.result[0];
						this.products = response.result;
						this.$store.commit('Loading');
					}); 
				}
			},
			VerXLS(){
				const xls = new XLS();
				xls.PagosInscriptos(this.nucleo.nombre,this.periodo.nombre,this.products);
			},
			hideDialog() {
				this.productDialog = false;
				this.submitted = false;
			},
			findIndexById(id) {
				let index = -1;
				for (let i = 0; i < this.products.length; i++) {
					if (this.products[i].id === id) {
						index = i;
						break;
					}
				}
				return index;
			},
			createId() {
				let id = '';
				var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				for ( var i = 0; i < 5; i++ ) {
					id += chars.charAt(Math.floor(Math.random() * chars.length));
				}
				return id;
			},
			exportCSV() {
				this.$refs.dt.exportCSV();
			},
			convfech(fecha){
				return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
			},
			IMG(imegan){
				this.imagen = imegan;
				this.toggle1(event);
			},
			toggle1(event) {
				this.$refs.op1.toggle(event);
			},


		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
