<template>
	<div class="p-grid crud-demo" >
		<div class="p-col-12" v-if="!detalles">
			<div class="card">
				<Toolbar class="p-col-12 p-md-12">
					<template v-slot:left>
						<Dropdown v-model="nucleo" :options="nucleos" optionLabel="nombre" placeholder="Seleccione la Extensión ..." @change="Validacion1()"/>
						<Dropdown v-model="periodo" :options="periodos" optionLabel="per" placeholder="Seleccione..." @change="Validacion2()" v-if="nucleo"/>
						<Dropdown v-model="plan" :options="planes" optionLabel="nombre" placeholder="Seleccione..." v-if="periodo"  @change="Buscar()"/>
					</template>
					<template v-slot:right>
                        <Button label="Export" icon="pi pi-upload" class="p-button-help" @click="VerXLS()"   v-if="products.length>0"/>					
                    </template>
				</Toolbar>

				<DataTable :value="products" ref="dt" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Preinscritos: {{products.length}}</h5>
							<Button icon="pi pi-file-pdf" class="p-button-rounded p-button-primary p-mr-2" @click="Ver()" v-if="products.length>0"/>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="fecha_reg" header="Fecha y Hora" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.fecha_reg}}
						</template>
					</Column>
					<Column field="cedula" header="Nº Cédula" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.cedula}}
						</template>
					</Column>
					<Column field="apellido" header="Apellidos y Nombres" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.apellido}}, {{slotProps.data.nombre}}
						</template>
					</Column>
					<Column field="plan" header="Plan de Estudio" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.plan}}
						</template>
					</Column>
					<Column field="turno" header="Turno" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.turno}}
						</template>
					</Column>
					<Column header="Pago">	
						<template #body="{data}">
							<span class="p-tag p-tag-success" v-if="data.pagada">Verificado</span>
							<span class="p-tag p-tag-danger" v-else>Por Verificar</span>
						</template>
					</Column>
					<Column header="Seleccionar">
						<template #body="{data}">
							<Button icon="pi pi-arrow-right" class="p-button-rounded p-button-success p-mr-2" @click="VerDetalles(data.id)" v-tooltip.bottom="'Seleccionar'" v-if="data.estatus==0 && data.pagada && edit && data.deuda<=0"/>
							<span class="p-tag p-tag-success" v-if="data.estatus>0">APROBADO</span><br>
							<span class="p-tag p-tag-danger" v-if="data.deuda>0">Tiene Deuda</span>							
						</template>
					</Column>
				</DataTable>
			</div>
		</div>
		<div class="p-col-12 p-md-12" v-if="detalles">
			<span class="p-tag p-tag-success" v-if="detalles.estatus>0">APROBADO</span>
			<span class="p-tag p-tag-danger" v-if="detalles.estatus==0">EN REVISIÓN</span>

			<div class="card">
				<Card>
					<template #title>
						<h5>Datos Personales</h5>
					</template>
					<template #content>
						<div class="p-fluid p-formgrid p-grid">
							<div class="p-field p-col-12 p-md-2">
								Nº Cedula: <strong>{{detalles.nac}}{{detalles.cedula}}</strong>
							</div>
							<div class="p-field p-col-12 p-md-2">
								Sexo: <strong v-if="detalles.sexo">Masculino</strong>
								<strong v-else>Femenino</strong>
							</div>
							<div class="p-field p-col-12 p-md-8">
								Nombres y Apellidos: <strong>{{detalles.nombre}} {{detalles.apellido}}</strong>
							</div>
							<div class="p-field p-col-12 p-md-8">
								Correo: <strong>{{detalles.correo}}</strong>
							</div>
							<div class="p-field p-col-12 p-md-4">
								Teléfono: <strong>{{detalles.tlf_celular}}</strong>
							</div>
							<div class="p-field p-col-12 p-md-4">
								Fecha de Nacmiento: <strong>{{convfech(detalles.fecha_nac)}}</strong>
							</div>
							<div class="p-field p-col-12 p-md-8">
								Lugar de Nacimiento: <strong>{{detalles.lugar_nac}} ({{detalles.pais_nac}})</strong>
							</div>		
							<div class="p-field p-col-12 p-md-8">
								Extensión: <strong>{{detalles.nucleo}}</strong>
							</div>		
							<div class="p-field p-col-12 p-md-4">
								Fecha de Registro: <strong>{{detalles.fecha}}</strong>
							</div>		
							<div class="p-field p-col-12 p-md-8">
								Carrera: <strong>{{detalles.plan}}</strong>
							</div>		
							<div class="p-field p-col-12 p-md-4">
								Turno: <strong>{{detalles.turno}}</strong>
							</div>		
							<div class="p-field p-col-12 p-md-4">
								<img style="width: 100px;" :src="anexos+'/ver/'+detalles.img_cedula" @click="IMG(anexos+'/ver/'+detalles.img_cedula)"/>
							</div>
						</div>
					</template>
				</Card>
			</div>
		</div>
		<div class="p-col-12 p-md-12" v-if="detalles">
			<div class="card">
				<Card>
					<template #title>
						<h5>Datos Académicos</h5>
					</template>
					<template #content>
						<div class="p-fluid p-formgrid p-grid">
							<div class="p-field p-col-12 p-md-12">
								Nombre del Planel: <strong>{{detalles.plantel}}</strong>
							</div>
							<div class="p-field p-col-12 p-md-8">
								Rama de la Educación Media: <strong>{{detalles.rama}}</strong>
							</div>
							<div class="p-field p-col-12 p-md-4">
								Año de Graduación: <strong>{{detalles.ano}}</strong>
							</div>
							<div class="p-field p-col-12 p-md-3">
								Título:								
							</div>
							<div class="p-field p-col-12 p-md-9">
								Notas:
							</div>
							<div class="p-field p-col-12 p-md-3">
								<img style="width: 100px;" :src="anexos+'/ver/'+detalles.img_titulo" @click="IMG(anexos+'/ver/'+detalles.img_titulo)"/>
							</div>
							<div class="p-field p-col-12 p-md-3" v-if="detalles.img_notas1">
								<img style="width: 100px;" :src="anexos+'/ver/'+detalles.img_notas1" @click="IMG(anexos+'/ver/'+detalles.img_notas1)"/>
							</div>
							<div class="p-field p-col-12 p-md-3" v-if="detalles.img_notas2">
								<img style="width: 100px;" :src="anexos+'/ver/'+detalles.img_notas2" @click="IMG(anexos+'/ver/'+detalles.img_notas2)"/>
							</div>
							<div class="p-field p-col-12 p-md-3" v-if="detalles.img_notas3">
								<img style="width: 100px;" :src="anexos+'/ver/'+detalles.img_notas3" @click="IMG(anexos+'/ver/'+detalles.img_notas3)"/>
							</div>
						</div>
					</template>
				</Card>
			</div>
		</div>
		<div class="p-col-12 p-md-12" v-if="detalles">
			<Button label="Aprobar" style="width: 110px;" icon="pi pi-check-circle" class="p-button-secondary p-mr-2" @click="Aprobar()" v-if="detalles.estatus==0"/>
			<Button label="Cancelar" style="width: 110px;" icon="pi pi-times" class="p-button-danger p-mr-2" @click="detalles=null"/>
		</div>
		<OverlayPanel ref="op1" appendTo="body" :showCloseIcon="true" >
			<img style="width: 400px;" :src="imagen" @click="VIMG(imagen)"/>
		</OverlayPanel>
	</div>

</template>

<script>
	import API from "../service/API";
	import PDF from "../service/PDF";
	import XLS from "../service/XLS";
	const Consulta = new API('Ingreso','Preinscritos');

	export default {
		data() {
			return {
				insert: false,
				edit: false,
				del: false,
				titulo: null,
				products: [],
				productDialog: false,
				deleteProductDialog: false,
				deleteProductsDialog: false,
				product: {},
				selectedProducts: null,
				filters: {},
				submitted: false,
				size: '60vw',
				nucleo: null,
				nucleos: [],
				plan: null,
				planes: [],
				periodo: null,
				periodos: [],
				detalles: null,
				visibletitulo: false,
				anexos: null,
			}
		},
		productService: null,
		created() {
			this.anexos = process.env.VUE_APP_ANEXOS;
			//this.url = process.env.VUE_APP_API;
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Ver(){
				const doc = new PDF();
				doc.Preinscritos(this.nucleo,this.periodo,this.products);
			},
			VerXLS(){
				const xls = new XLS();
				xls.Preinscritos(this.nucleo,this.periodo,this.products);
			},
			VIMG(data){
				window.open(data, '_blank');
			},
			Mostrar() {
				Consulta.Ini().then(result => {
					//this.$store.state.error = result;
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					this.nucleos = result.consult.nucleos;				
					this.$store.commit('Loading');
				}); 
			},
			Validacion1() {
				this.plan = null;
				this.planes = [];
				this.periodo = null;
				this.periodos = [];
                this.products = [];
				Consulta.Procesar('Validacion1',{
					nucleo: this.nucleo.code,
				}).then(response => {
					//this.$store.state.error = response;	
					this.periodos = response.result;
                    this.$store.commit('Loading');
				});
			},
			Validacion2() {
				this.plan = null;
				this.planes = [];
                this.products = [];
				Consulta.Procesar('Validacion2',{
					nucleo: this.nucleo.code,
					periodo: this.periodo.code,
				}).then(response => {
					//this.$store.state.error = response.result.data;	
					this.planes = response.result.planes;
                    this.products = response.result.data;
                    this.$store.commit('Loading');
				});
			},
			Buscar() {
				if (this.nucleo != null && this.periodo != null) {
					this.detalles = null;
                    this.products = [];
					Consulta.Procesar('Buscar',{
						nucleo: 	this.nucleo.code,
						plan: 	    this.plan.code,
						periodo: 	this.periodo.code,
					}).then(response => {
						//this.$store.state.error = response.result;
						this.products = response.result;
						this.$store.commit('Loading');
					}); 
				}
			},
			VerDetalles(id) {
				this.detalles = null;
				Consulta.Procesar('Seleccion',{
					id: 	id,
				}).then(response => {
					//this.$store.state.error = response;
					this.detalles = response.result;
					this.$store.commit('Loading');
				}); 
			},
			Aprobar(){
				Consulta.Procesar('UPD',{
					est: this.detalles,
					}).then(response => {
						//this.$store.state.error =response;
						if (response.result) {
							this.$toast.add({
								severity:'success', 
								summary: 'Éxito', 
								detail: 'Información Procesada', 
								life: 3000
							});
							this.$store.commit('Loading');
							this.VerDetalles(this.detalles.id);
						} else {
							this.$toast.add({
								severity:'warn', 
								summary: 'Advertencia', 
								detail: 'No fue procesada la solicitud', 
								life: 3000
							});
							this.$store.commit('Loading');
						}
					});
			},

			hideDialog() {
				this.productDialog = false;
				this.submitted = false;
			},
			IMG(imegan){
				this.imagen = imegan;
				this.toggle1(event);
			},
			toggle1(event) {
				this.$refs.op1.toggle(event);
			},
			findIndexById(id) {
				let index = -1;
				for (let i = 0; i < this.products.length; i++) {
					if (this.products[i].id === id) {
						index = i;
						break;
					}
				}

				return index;
			},
			createId() {
				let id = '';
				var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				for ( var i = 0; i < 5; i++ ) {
					id += chars.charAt(Math.floor(Math.random() * chars.length));
				}
				return id;
			},
			exportCSV() {
				this.$refs.dt.exportCSV();
			},
				convfech(fecha){
					return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
				},

		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
