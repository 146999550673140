<template>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card">
				<Card>
					<template #title>
						<h5>Solicitudes</h5>
					</template>
					<template #content>
                        <Toolbar class="p-col-12 p-md-12">
                        <template v-slot:left>
                            <Dropdown v-model="opcion" :options="opciones" optionLabel="nombre" placeholder="Seleccione..."/>
                            <div v-if="opcion">
                                <InputText type="number" min="0" v-model="buscar" required="true" placeholder="Cédula" v-if="opcion.code==1"/>
                                <Dropdown v-model="nucleo" :options="nucleos" optionLabel="nombre" placeholder="Seleccione la Extensión ..." v-if="opcion.code==2"/>
                                <Dropdown v-model="pendiente" :options="pendientes" optionLabel="nombre" placeholder="Seleccione..." v-if="nucleo && opcion.code==2"/>
                                <Dropdown v-model="year" :options="years" optionLabel="nombre" placeholder="Seleccione..." v-if="pendiente && opcion.code==2"/>
                                <Dropdown v-model="mes" :options="meses" optionLabel="nombre" placeholder="Seleccione..." v-if="year && opcion.code==2"/>
                            </div>
                        </template>				
                        <template v-slot:right>
                            <Button label="Buscar" icon="pi pi-search" class="p-button-success p-mr-2" @click="Buscar" v-if="opcion"/>
                        </template>
                    </Toolbar>
                        <DataTable :value="solicitudes" :scrollable="true" scrollHeight="300px" class="p-datatable-sm">
                            <Column header="Fecha" headerStyle="width: 100px">
                                <template #body="slotProps">
                                    {{slotProps.data.registro}}<br>
                                    N° {{(slotProps.data.id)}}
                                </template>	
                            </Column>  
                            <Column header="Estudiante" headerStyle="width: 150px">
                                <template #body="slotProps">
                                    {{slotProps.data.apellido}}<br>
                                    {{slotProps.data.nombre}}<br>
                                    {{slotProps.data.nac}}{{slotProps.data.estudiante}}
                                </template>	
                            </Column>  
                            <Column header="Descripción">	
                                <template #body="{data}">
                                    {{data.arancel}}
                                </template>
                            </Column>
                            <Column header="Etapa" headerStyle="width: 180px">	
                                <template #body="{data}">
                                    {{data.etapa}}<br>
									<ProgressBar :value="(data.paso*100/data.etapas).toFixed(0)"></ProgressBar>
                                </template>
                            </Column>
							<Column header="Observación" headerStyle="width: 220px">	
                                <template #body="{data}">
                                    <strong v-if="data.fecha">Procesado: {{data.fecha}}</strong>
                                </template>
                            </Column>
                            <Column header="Selec" headerStyle="width: 50px">
                                <template #body="{data}">
									<i class="pi pi-check-circle" style="fontSize: 1.5rem" v-if="data.entregado"></i>
                                    <Button icon="pi pi-pencil" class="p-button-rounded p-button-info p-mr-2" @click="Editar(data)" v-tooltip.bottom="'Editar'" :disabled="data.pagada==false" v-else/>
                                </template>
                            </Column>
                        </DataTable>
					</template>
				</Card>
			</div>
        </div>

		<Dialog v-model:visible="Dialog" :style="{width: size}" :header="'Estado de la Solicitud N° '+solic " :modal="true" class="p-fluid" :maximizable="true">
            <br>
            <div class="p-fluid p-formgrid p-grid">
				<div class="p-field p-col-12 p-md-12">
					Extensión: <strong>{{product.extension}}</strong>
				</div>
				<div class="p-field p-col-12 p-md-4">
					Nº Cedula: <strong>{{product.nac}}{{product.estudiante}}</strong>
				</div>
				<div class="p-field p-col-12 p-md-8">
					Nombres y Apellidos: <strong>{{product.nombre}} {{product.apellido}}</strong>
				</div>
				<div class="p-field p-col-12 p-md-4">
					Teléfono: <strong>{{product.tlf_celular}}</strong>
				</div>
				<div class="p-field p-col-12 p-md-8">
					Correo: <strong>{{product.correo}}</strong>
				</div>
			</div>
			<br><br>
            <div class="p-fluid p-formgrid p-grid">
				<div class="p-field p-col-12 p-md-12">
					Trámite: <strong>{{product.arancel}}</strong>
				</div>
				<div class="p-field p-col-12 p-md-12">
					<label>Observación</label>
					<Textarea v-model="product.observacion"  rows="2"/>						
				</div>
			</div>
			<br>
            <div class="p-fluid p-formgrid p-grid">
				<div class="p-field p-col-12 p-md-6 p-grid">
					<div class="p-field p-col-12 p-md-12">
						Fecha de Registro: <strong>{{product.registro}}</strong>
					</div>
					<div class="p-field p-col-12 p-md-12">
						Factura N°: <strong>{{product.factura}}</strong>
					</div>
					<div class="p-field p-col-12 p-md-12">
						Fecha de culminación de proceso: <strong>{{product.entregado}}</strong>
					</div>
				</div>
				<div class="p-field p-col-12 p-md-6 p-grid">
					<div class="p-field p-col-12 p-md-12">
						<strong>Etapa del Proceso</strong>
					</div>
					<div v-for="category in etapas" :key="category.key" class="p-field p-col-12 p-md-12">
						<RadioButton v-model="product.etapa_id" :inputId="category.key" name="dynamic" :value="category.code" :disabled="etapa>=category.code"/>
						<label :for="category.key" class="p-ml-2">{{ category.nombre }}</label>
					</div>
				</div>
			</div>
			<template #footer>
				<Button label="Guardar" icon="pi pi-check" class="p-button-secondary" @click="Guardar()" />
				<Button label="Cerrar" icon="pi pi-times" class="p-button-danger" @click="Dialog = false"/>
			</template>
		</Dialog>
   </div>
</template>

<script>
import API from "@/service/API";
const Consulta = new API('Solicitudes','Tramites');

export default {
    data () {
        return {
            year: null,
			years: [],
			mes: null,
			meses: [
				{nombre: 'Enero', code: 1},
				{nombre: 'Febrero', code: 2},
				{nombre: 'Marzo', code: 3},
				{nombre: 'Abril', code: 4},
				{nombre: 'Mayo', code: 5},
				{nombre: 'Junio', code: 6},
				{nombre: 'Julio', code: 7},
				{nombre: 'Agosto', code: 8},
				{nombre: 'Septiembre', code: 9},
				{nombre: 'Octubre', code: 10},
				{nombre: 'Noviembre', code: 11},
				{nombre: 'Diciembre', code: 12},
			],
			opcion: null,
			opciones: [
				{nombre: 'Buscar por Cédula', code: 1},
				{nombre: 'Buscar por Extensión', code: 2},
			],
			nucleo: null,
			nucleos: [],
			etapa: null,
			etapas: [],
			pendiente: {nombre: 'Por Validar Pago', code: 0},
			pendientes:  [
                {nombre: 'Por Validar Pago', code: 0},
				{nombre: 'Paso 1', code: 1},
                {nombre: 'Paso 2', code: 2},
                {nombre: 'Paso 3', code: 3},
			],
			buscar: null,
            solicitudes: [],
			product: null,
            Dialog: false,
			size: '60vw',
			token: null,
            solic: 0,
        }
    },
	created() {
        if (this.$store.state.mobile){this.size = '90vw';}
		this.Mostrar();
        for (let i = this.$store.state.year; i > 2020 ; i--) {
			this.years.push({nombre: i, code: i});
		}
		this.year = this.years[0];
		this.mes = this.meses[this.$store.state.mes-1];
	},
    methods: {
        Mostrar() {
            this.tipos = [];
            this.product = null;
			Consulta.Ini().then(result => {
				//this.$store.state.error = result;
				this.insert = result.insert;
				this.edit = result.edit;
				this.del = result.delete;
                this.nucleos = result.consult.nucleos;
                this.etapas = result.consult.etapas;
				this.pendientes = [{nombre: 'Por Validar Pago', code: 0}].concat(result.consult.etapas);
				this.pendientes = this.pendientes.concat([{nombre: 'Todos', code: 11}]);
				this.$store.commit('Loading');
			}); 
        },
        Buscar() {
			if ((this.buscar && this.opcion.code==1) || (this.opcion.code==2 && this.nucleo && this.pendiente
				&& this.year && this.mes)
			){
				Consulta.Procesar('Buscar',{
					id: 	this.buscar,
					opcion: this.opcion,
					nucleo: this.nucleo,
					pendiente: this.pendiente,
					mes: this.mes.code,
					year: this.year.code,
				}).then(response => {
					//this.$store.state.error =response;
                    this.solicitudes = response.result;
					this.$store.commit('Loading');
				}); 
			} else {
				this.$toast.add({
					severity:'warn', 
					summary: 'Advertencia', 
					detail:'Debes colocar los datos solicitados', 
					life: 3000
				});    
			}
		},
        Editar(data){
			this.solic = data.id;
			this.etapa = data.etapa_id;
            this.product = {...data};
            this.Dialog = true;
        },
        Guardar(){
            Consulta.Procesar('UPD',{
                product: this.product,
            }).then(response => {
                //this.$store.state.error =response;
                this.$store.commit('Loading');
				if (response.result) {
					this.Dialog = false;
					this.$toast.add({
						severity:'success', 
						summary: 'Éxito', 
						detail: 'Información Procesada', 
						life: 3000
					});
					this.Buscar();
				} else {
					this.$toast.add({
						severity:'warn', 
						summary: 'Advertencia', 
						detail: 'No fue procesada la solicitud', 
						life: 3000
					});
				}
            });
        },


    }
}
</script>