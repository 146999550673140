<template>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card">
				<Card>
					<template #title>
						<h5>Deudas</h5>
					</template>
					<template #content>
                        <DataTable :value="deudas" :scrollable="true" scrollHeight="500px" class="p-datatable-sm">
                            <Column header="Fecha">	
                                <template #body="{data}">
                                    {{data.fecha_reg}}
                                </template>
                            </Column>
                            <Column header="Descripción">	
                                <template #body="{data}">
                                    {{data.descripcion}}
                                </template>
                            </Column>
                            <Column header="Monto">	
                                <template #body="{data}">
                                    <strong v-if="data.moneda==1">Ref.</strong>
                                    <img :src="'images/moneda'+data.moneda+'.png'" width="15"/>
                                    {{data.monto}}
                                </template>
                            </Column>
                            <Column header="Recargo">	
                                <template #body="{data}">
                                    <strong v-if="data.moneda==1">Ref.</strong>
                                    <img :src="'images/moneda'+data.moneda+'.png'" width="15"/>
                                    {{data.recargo}}
                                </template>
                            </Column>
                            <Column header="Monto Bs">	
                                <template #body="{data}">
                                    <div v-if="tasa>1 && moneda">
                                        Bs. {{new Intl.NumberFormat("de-DE").format(((parseFloat(data.monto)+parseFloat(data.recargo))*tasa).toFixed(2))}}
                                    </div>
                                </template>
                            </Column>
                            <Column header="Vencimiento">	
                                <template #body="{data}">
                                    <strong v-if="data.recargo>0" style="color: red;">{{data.vence}}</strong>
                                    <strong v-else>{{data.vence}}</strong>
                                </template>
                            </Column>
                            <Column header="Agregar a Factura">
                                <template #body="{data, index}">
                                    <div v-if="index==0">
                                        <div v-if="deudas.length>1">
                                            <Checkbox v-model="data.select" :binary="true" @click="AgregarFactura(data)" :disabled="deudas[index+1].select==true"/>
                                        </div>
                                        <div v-else>
                                            <Checkbox v-model="data.select" :binary="true" @click="AgregarFactura(data)"/>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div v-if="index>0 && deudas[index-1].select==true && (index+1)<deudas.length">
                                            <Checkbox v-model="data.select" :binary="true" @click="AgregarFactura(data)" :disabled="deudas[index+1].select==true"/>
                                        </div>
                                        <div v-if="(index+1)==deudas.length && deudas[index-1].select==true">
                                            <Checkbox v-model="data.select" :binary="true" @click="AgregarFactura(data)"/>
                                        </div>
                                    </div>
                                </template>
                            </Column>
                        </DataTable>
					</template>
				</Card>
			</div>
        </div>

		<div class="p-col-12">
			<div class="card">
				<Card>
					<template #title>
						<h5>Factura a Pagar</h5>
					</template>
					<template #content>
                        <DataTable :value="factura" :scrollable="true" scrollHeight="500px" class="p-datatable-sm">
                            <Column header="Fecha">	
                                <template #body="{data}">
                                    {{data.fecha}}
                                </template>
                            </Column>
                            <Column header="Descripción">	
                                <template #body="{data}">
                                    {{data.descripcion}}
                                </template>
                            </Column>
                            <Column header="Monto">	
                                <template #body="{data}">
                                    <div v-if="data.moneda>0">
                                        <strong v-if="data.moneda==1">Ref.</strong>
                                        <div v-if="ultimafecha>new Date(data.vencimiento)">
                                            <img :src="'images/moneda'+data.moneda+'.png'" width="15"/>
                                            {{parseFloat(data.monto)+parseFloat(data.recargo)}}
                                        </div>
                                        <div v-else>
                                            <img :src="'images/moneda'+data.moneda+'.png'" width="15"/>
                                            {{parseFloat(data.monto)}}
                                        </div>
                                    </div>
                                    <div v-if="data.moneda==0 && tasa!=1">
                                        <strong v-if="moneda==1">Ref.</strong>
                                        <img :src="'images/moneda'+moneda+'.png'" width="15"/>
                                        {{parseFloat(data.montobs/tasa).toFixed(2)}}
                                    </div>
                                </template>
                            </Column>
                            <Column header="Monto Bs">	
                                <template #body="{data}">
                                    <div v-if="data.moneda>0">
                                        <div v-if="tasa>1 && moneda">
                                            <div v-if="ultimafecha>new Date(data.vencimiento)">
                                                Bs. {{new Intl.NumberFormat("de-DE").format(((parseFloat(data.monto)+parseFloat(data.recargo))*tasa).toFixed(2))}}
                                            </div>
                                            <div v-else>
                                                Bs. {{new Intl.NumberFormat("de-DE").format(((parseFloat(data.monto))*tasa).toFixed(2))}}
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else>
                                        Bs. {{new Intl.NumberFormat("de-DE").format((parseFloat(data.montobs)).toFixed(2))}}
                                    </div>
                                </template>
                            </Column>
                        </DataTable>
                        <h5>Total de Factura:
                            <strong v-if="moneda==1">Ref.</strong>
                            <strong>
                                <img :src="'images/moneda'+moneda+'.png'" width="15" v-if="moneda"/>
                                {{factura_monto.toFixed(2)}}
                            </strong>
                        </h5>
                        <br><br>
                        <Button label="Agregar Pago" icon="pi pi-money-bill" class="p-button-info p-mr-2 p-mb-2" @click="CargarPago()" style="width: 200px;"/>
                        <small v-show="validationErrors.pagos && submitted" style="color: red;">Debe reportar el pago</small>
                        <DataTable :value="pagos" :scrollable="true" scrollHeight="500px" class="p-datatable-sm">
                            <Column header="Fecha">	
                                <template #body="{data}">
                                    {{data.fecha}}
                                </template>
                            </Column>
                            <Column header="Tipo">	
                                <template #body="{data}">
                                    {{data.tipo.nombre}}
                                </template>
                            </Column>
                            <Column header="Banco">	
                                <template #body="{data}">
                                    {{data.banco_orig.nombre}}
                                    <div v-if="data.banco">
                                        <br>{{data.banco.nombre}}
                                    </div>    
                                    <div v-if="data.movil">
                                        <br>{{data.banco.nombre}}
                                    </div> 
                                </template>
                            </Column>
                            <Column header="Referencia">	
                                <template #body="{data}">
                                    {{data.referencia}}
                                    <img style="width: 100px;" :src="anexos+'/ver/'+data.img" v-if="data.img"/>
                                </template>
                            </Column>
                            <Column header="Monto Bs">	
                                <template #body="{data}">
                                    Bs. {{new Intl.NumberFormat("de-DE").format((data.montobs).toFixed(2))}}
                                </template>
                            </Column>
                            <Column header="Monto">	
                                <template #body="{data}">
                                    <strong v-if="moneda==1">Ref.</strong>
                                    <img :src="'images/moneda'+moneda+'.png'" width="15"/>
                                    {{(data.monto).toFixed(2)}}
                                </template>
                            </Column>
                            <Column header="Eliminar">
                                <template #body="{data}">
                                    <Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="EliminarPago(data)"/>
                                </template>
                            </Column>
                        </DataTable>                   
                        <h5>Total de Pagos: 
                            <strong v-if="moneda==1">Ref.</strong>
                            <strong>
                                <img :src="'images/moneda'+moneda+'.png'" width="15" v-if="moneda"/>
                                {{pago_monto.toFixed(2)}}
                            </strong>
                        </h5>
                        <h5>
                            <strong v-if="pago_monto<factura_monto">
                                Diferencia: <strong v-if="moneda==1">Ref.</strong>
                                <img :src="'images/moneda'+moneda+'.png'" width="15"/>
                                {{(factura_monto-pago_monto).toFixed(2)}}
                                 (Bs. {{new Intl.NumberFormat("de-DE").format(((factura_monto-pago_monto)*tasa).toFixed(2))}})
                            </strong>
                            <strong v-if="pago_monto>factura_monto">
                                A favor: 
                                <strong v-if="moneda==1">Ref.</strong>
                                <img :src="'images/moneda'+moneda+'.png'" width="15"/>
                                {{(pago_monto-factura_monto).toFixed(2)}}
                                 (Bs. {{new Intl.NumberFormat("de-DE").format(((pago_monto-factura_monto)*tasa).toFixed(2))}})
                            </strong>
                        </h5>
					</template>
					<template #footer>
                        <Button label="Procesar" style="width: 110px;" icon="pi pi-check-circle" class="p-button-secondary p-mr-2" @click="Procesar()"/>
					</template>
				</Card>
			</div>
        </div>


		<Dialog v-model:visible="Dialog" :style="{width: size}" header="Agregar Pago" :modal="true" class="p-fluid" :maximizable="true">
            <br>
            <div class="p-field">
				<div class="p-grid">
					<div class="p-col-8">
                        <label>Tipo</label>
                            <div v-for="(stats, index) in tipos" :key="index">
                                <RadioButton id="tipo" name="tipo" :value="stats.code" v-model="pago.tipo"/>
                                <strong> {{stats.nombre}}<br><br></strong>
                            </div>
					</div>
					<div class="p-col-4" v-if="pago.tipo">
                        <label>Fecha</label>
                        <InputText v-model="pago.fecha" type="date" :max="$store.state.fechaini"/>
					</div>
					<div class="p-col-7" v-if="pago.tipo">
                        <strong v-if="pago.tipo==2">{{banco_destino.nombre}}</strong>
						<label v-if="pago.tipo==3 || pago.tipo==4">Banco de Origen</label>
						<Dropdown v-model="pago.banco_orig" :options="bancos" optionLabel="nombre" placeholder="Seleccione..." v-if="pago.tipo==1 || pago.tipo==3 || pago.tipo==4"/>
					</div>
					<div class="p-col-7" v-if="pago.tipo">
                        <label>Referencia</label>
                        <InputText v-model="pago.referencia" type="number" placeholder="Número" min="0"/>
					</div>
                    <div class="p-col-5" v-if="pago.tipo==4">
                        <label>N° de celular de origen</label>
                        <InputText v-model="pago.movil" type="number" placeholder="Número" min="0"/>
					</div>
					<div class="p-col-5" v-if="pago.tipo">
                        <label>Monto Bs</label>
                        <InputNumber v-model="pago.montobs" required="true" min="1" :max="$store.state.tope" :minFractionDigits="2"/>
					</div>
                    <div class="p-field p-col-12 p-md-4" v-if="pago.tipo">
                        <FileUpload chooseLabel="Subir Foto JPG" uploadLabel="Cargar" mode="basic" :name="token" :url="anexos+'/pagos'"  accept="image/jpeg" :maxFileSize="1000000" @upload="myUploader(token)" :auto="true"/>
                    </div>
					<div class="p-field p-col-4 p-md-4" v-if="pago.tipo">
						<img style="width: 100px;" :src="anexos+'/ver/'+pago.img" v-if="pago.img"/>
					</div>
				</div>
			</div>
			<template #footer>
				<Button label="Agregar" icon="pi pi-check" class="p-button-secondary" @click="Agregar()" />
				<Button label="Cancelar" icon="pi pi-times" class="p-button-danger" @click="Dialog = false"/>
			</template>
		</Dialog>

    </div>
</template>

<script>
import API from "@/service/API";
import Token from "uuid-token-generator";
const Consulta = new API('AreaEstudiante','ReportarMiPago');

export default {
    data () {
        return {
			iniciar: false,
			buscar: null,
            bancos: [],
            banco_destino: {code: 3, nombre: 'Banco Mercantil'},
            tipos: [],
			opcion: true,
			productDialog: false,
			product: null,
			filters: {},
            products: [],
            deudas: [],
            submitted: false,
            validationErrors: {},
            pagos: [],
            deuda: [],
            factura: [],
            previo: [],
            factura_monto: 0,
            factura_montobs: 0,
            pago_montobs: 0,
            pago_monto: 0,
            Dialog: false,
            pago: {
                fecha: null,
                tipo: null,
                banco_orig: null,
                banco: 3,
                referencia: null,
                tasa: null,
                moneda: null,
                monto: null,
                montobs: null,
                img: null,
                movil: 0,
            },
			size: '40vw',
			token: null,
            ultimafecha: null,
            moneda: null,
            tasa: 1,
            anexos: null,
        }
    },
	created() {
        this.anexos = process.env.VUE_APP_ANEXOS;
        //this.url = process.env.VUE_APP_API;
        if (this.$store.state.mobile){this.size = '90vw';}
		this.Mostrar();
	},
    methods: {
        Mostrar() {
            this.iniciar = false;
            this.bancos = [];
            this.tipos = [];
            this.factura = [];
            this.pagos = [];
			Consulta.Ini().then(result => {
				//this.$store.state.error = result;
				this.insert = result.insert;
				this.edit = result.edit;
				this.del = result.delete;
                this.bancos = result.consult.bancos;
                this.tipos = result.consult.tipos;
                this.deudas = result.consult.deudas.filter(val => val.monto>0);
                this.factura = result.consult.deudas.filter(val => val.montobs<0);
				this.$store.commit('Loading');
			}); 
        },
        AgregarFactura(data){
            if(this.moneda==data.moneda || this.moneda==null){
                this.moneda = data.moneda;
                if(this.moneda==1){this.tasa = this.$store.state.dolar;}
                if(this.moneda==2){this.tasa = this.$store.state.petro;}
                if (!data.select){
                    this.factura.push(data);
                } else {
                    this.factura = this.factura.filter(val => val !== data);
                }
                this.Calcular();
            }
        },
		UltimaFech() {
            if(this.pagos.length>0){
                var fechas = [];
                this.pagos.forEach(element => {
                    fechas.push(element.fecha);
                }); 
                let arrayFechas = fechas.map((fechaActual) => new Date(fechaActual) );
                this.ultimafecha = new Date(Math.max.apply(null,arrayFechas));
            } else {
                this.ultimafecha = null;
            }
		},
		Calcular() {
            if(this.factura.length>0){
                var monto = 0;
                this.factura.forEach(element => {
                    if(element.moneda>0){
                        if(this.ultimafecha && this.ultimafecha>new Date(element.vencimiento)){
                            monto = parseFloat(monto) + parseFloat(element.monto) + parseFloat(element.recargo);
                        } else {
                            monto = parseFloat(monto) + parseFloat(element.monto);
                        }
                    } else {
                        monto = parseFloat(monto) + parseFloat(element.montobs/this.tasa);
                    }
                }); 
                this.factura_monto = monto;
            } else {
                this.factura_monto = 0;
            }
		},
        Procesar(){
            if (parseFloat(this.pago_monto+0.000001) < parseFloat(this.factura_monto)) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Debe reportar el Pago Completo', life: 5000});
			} else if (this.factura.length==0) {
                this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'No hay factura a procesar', life: 10000});
			} else if (this.pagos.length==0 && this.pago_monto < this.factura_monto) {
                this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'No hay pago a procesar', life: 10000});
            } else {
                Consulta.Procesar('INS',{
                    factura: this.factura,
                    pagos: this.pagos,
                    pago_montobs: this.pago_montobs,
                    factura_monto: this.factura_monto,
                    moneda: this.moneda,
                }).then(response => {
                    //this.$store.state.error =response;
                    if(response.result){
                        this.Mostrar();
                    }
                    this.$store.commit('Loading');
                });
            }
        },
        CargarPago() {
            if(this.factura_monto>0){
                const tokgen = new Token();
                this.token = tokgen.generate();
                this.pago = {
                    fecha: this.$store.state.fechaini,
                    tipo: null,
                    banco_orig: null,
                    banco: null,
                    referencia: null,
                    montobs: null,
                    tasa: null,
                    monto: null,
                    moneda: this.moneda,
                    img: null,
                    movil: 0,
                };
                this.Dialog = true;
            }
        },
		myUploader(img) {
            this.pago.img = img;
            const tokgen = new Token();
                this.token = tokgen.generate();
                /*
			Consulta.Procesar('ImgPagos',{
				img: img,
			}).then(response => {
                if(response.result){
                    this.pago.img = this.token;
                }
                const tokgen = new Token();
                this.token = tokgen.generate();

				this.$store.commit('Loading');
			});
            */
        },
		EliminarPago(selec) {
            this.pago_montobs = 0;
            this.pago_monto = 0;
			this.pagos = this.pagos.filter(val => val !== selec);
            this.UltimaFech();
            this.Calcular();
		},
        EliminarFactura(selec){
            this.deuda.push(selec);
            //this.factura_monto = parseFloat(this.factura_monto) - parseFloat(selec.monto);
            this.Calcular();
            this.factura_montobs = parseFloat(this.factura_monto)*this.tasa;
			this.factura = this.factura.filter(val => val !== selec);
        },
        Agregar(){
            if (this.pago.tipo == null) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione el tipo de pago', life: 5000});
			} else if (this.pago.fecha == null) {
                this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Indique la fecha del pago', life: 10000});
			} else if (this.pago.banco_orig == null && (this.pago.tipo == 1 || this.pago.tipo == 3 || this.pago.tipo == 4)) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione el banco', life: 5000});
			} else if (this.pago.movil == 0 && this.pago.tipo == 4) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduz el número de celular de origen', life: 5000});
			} else if (this.pago.referencia == null) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca el número de referencia del pago', life: 5000});
			} else if (this.pago.montobs == null) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca el monto del pago', life: 5000});
			} else if (this.pago.img == null) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Debe cargar la imagen de la transferencia', life: 5000});
            } else {
                this.$store.commit('Loading');
                this.pago.banco = this.banco_destino;
                if (this.pago.tipo==2){
                    this.pago.banco_orig = this.banco_destino;
                }
				this.tipos.forEach(element => {
					if(element.code == this.pago.tipo){
						this.pago.tipo = element;
					}
				}); 
                Consulta.Procesar('VerifPago',{
                    id: this.$store.state.matricula,
                    pago: this.pago,
                    moneda: this.moneda,
                }).then(response => {
                    //this.$store.state.error =response;
                    if (response.result.verificacion && response.result.tasa>0) {
						this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
                        this.pago.tasa = response.result.tasa;
                        //this.pago.monto = parseFloat(this.pago.montobs) / parseFloat(this.pago.tasa);
                        this.pago.monto = Math.round((parseFloat(this.pago.montobs) / parseFloat(this.pago.tasa)) * 100) / 100;
                        this.pagos.push(this.pago);
                        this.pago_montobs = parseFloat(this.pago_montobs) + parseFloat(this.pago.montobs);
                        this.pago_monto = parseFloat(this.pago_monto) + parseFloat(this.pago.monto);
                        this.UltimaFech();
                        this.Calcular();
                        this.Dialog = false;
                    } else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: response.result.mens, 
							life: 3000
						});
                        this.Dialog = false;
                    }
                    this.$store.commit('Loading');
                });
            }
        },
        validateForm() {
            if (!this.formapago)
                this.validationErrors['formapago'] = true;
            else
                delete this.validationErrors['formapago'];
            if (this.pagos.length==0)
                this.validationErrors['pagos'] = true;
            else
                delete this.validationErrors['pagos'];

            return !Object.keys(this.validationErrors).length;
        },
    }
}
</script>